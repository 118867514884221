import { useEffect, useState } from 'react';
import Flag from 'react-flagpack';
import 'react-flagpack/dist/style.css';

interface AppFlagIconProps {
    code: string;
}

const AppFlagIcon = ({ code }: AppFlagIconProps) => {
    const [flagCode, setFlagCode] = useState<string>(code.toUpperCase());
    useEffect(() => {
        if (code === 'gb') {
            setFlagCode('GB-UKM');
        }
    }, [code]);

    return (
        <Flag
            size="m"
            code={flagCode}
            hasBorderRadius={true}
            hasDropShadow={true}
        />
    );
};

export default AppFlagIcon;
