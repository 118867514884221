import { useEffect, useMemo, useState } from 'react';
import PrometheusStatService from '../../services/PrometheusStatService';
import moment from 'moment';
import PrometheusPostgreSqlReplicaLagStats from '../../types/PrometheusReplicaLag';

interface Props {
    dataStoreUuid?: string;
    offset?: number;
    limit?: number;
}

interface ReplicaInfo {
    replicaLag: number;
    created: number;
}

interface ReplicaData {
    [key: string]: ReplicaInfo;
}

export default function useReplicaLag({ dataStoreUuid }: Props) {
    const [replicaLag, setReplicaLag] = useState<ReplicaData>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
            setLoading(false);
        };

        if (!replicaLag) {
            init();
        } else {
            refresh();
        }
    }, [dataStoreUuid]);

    const refresh = () => {
        const update = async () => {
            setUpdating(true);
            await load();
            setUpdating(false);
        };
        update();
    };

    const load = async () => {
        const from = moment()
            .subtract(5, 'minutes')
            .format('YYYY-MM-DDTHH:mm:ssZ');
        const to = moment().format('YYYY-MM-DDTHH:mm:ssZ');

        if (dataStoreUuid) {
            try {
                const { PrometheusReplicaLag } =
                    await PrometheusStatService.getReplicaLag(
                        dataStoreUuid,
                        '',
                        from,
                        to
                    );
                if (PrometheusReplicaLag.length) {
                    return findLatest(PrometheusReplicaLag);
                }
            } catch (e) {
                console.error(e);
            }
        }
    };

    const findLatest = (lags: PrometheusPostgreSqlReplicaLagStats[]) => {
        const replicaLagObject: any = {};
        lags.forEach((lag) => {
            if (!replicaLagObject[lag.instance]) {
                replicaLagObject[lag.instance] = {
                    replicaLag: (lag.replicaLag / 1000).toFixed(3),
                    created: lag.created,
                };
            } else {
                if (
                    new Date(lag.created) >
                    new Date(replicaLagObject[lag.instance].created)
                ) {
                    replicaLagObject[lag.instance] = {
                        replicaLag: (lag.replicaLag / 1000).toFixed(3),
                        created: lag.created,
                    };
                }
            }
        });
        setReplicaLag(replicaLagObject);
        return replicaLagObject;
    };

    return { replicaLag, refresh, error, loading };
}
